<template>
    <div :class="cwo.criticalTask ? 'content-box critical-task' : 'content-box'">
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols-lg="6">
                    <b-form-group
                        class="row"
                        :label="$t('forms.utcDate')"
                        label-for="utcDate"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input v-model="currentDate" id="utcDate" disabled/>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.operator')"
                        label-for="operator"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="operator"
                            v-model="customerSelect"
                            :options="customers"
                        />
                        <div
                            class="input-errors"
                            v-for="error of vCwo$.customerSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: vCwo$.cwo.customerInfo.$errors.length }"
                        :label="$t('forms.customer')"
                        label-for="customer"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-textarea v-model="cwo.customerInfo" id="customer"/>
                        <div
                            class="input-errors"
                            v-for="error of vCwo$.cwo.customerInfo.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: vCwo$.acTypeSelect.$errors.length }"
                        :label="$t('forms.acType')"
                        label-for="acTypeSelect"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="acTypeSelect"
                            v-model="acTypeSelect"
                            :options="acTypes"
                        />
                        <div
                            class="input-errors"
                            v-for="error of vCwo$.acTypeSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.acRegistration')"
                        label-for="acRegistration"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input v-model="cwo.acRegistration" id="acRegistration"/>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: vCwo$.cwo.serviceRequested.$errors.length }"
                        :label="$t('forms.serviceRequested')"
                        label-for="serviceRequested"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="serviceSelect"
                            v-model="serviceSelect"
                            :options="services"
                        />
                        <b-form-textarea
                            v-model="cwo.serviceRequested"
                            id="serviceRequested"
                            placeholder="Select service request above. You can still write your own text."
                            rows="10"
                        />
                        <div
                            class="input-errors"
                            v-for="error of vCwo$.cwo.serviceRequested.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols-lg="6">
                    <div>
                        <b-form-group
                            :class="{ row, error: vCwo$.stationSelect.$errors.length }"
                            :label="$t('forms.station')"
                            label-for="stationSelect"
                            label-cols-md="4"
                            content-cols-lg="8"
                        >
                            <v-select
                                id="stationSelect"
                                v-model="stationSelect"
                                :options="stations"
                            />
                            <div
                                class="input-errors"
                                v-for="error of vCwo$.stationSelect.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>
                        <b-form-group
                            class="row"
                            :label="$t('forms.provider')"
                            label-for="provider"
                            label-cols-md="4"
                            content-cols-lg="8"
                        >
                            <b-form-textarea
                                v-model="cwo.providerInfo"
                                id="provider"
                                rows="3"
                                disabled
                            />
                        </b-form-group>
                        <b-form-group
                            class="row"
                            :label="$t('forms.arrivalFlightNumber')"
                            label-for="arrivalFlightNumber"
                            label-cols-md="4"
                            content-cols-lg="8"
                        >
                            <b-form-input
                                v-model="cwo.arrivalFlightNumber"
                                id="arrivalFlightNumber"
                            />
                        </b-form-group>
                        <b-form-group
                            class="row"
                            :label="$t('forms.departureFlightNumber')"
                            label-for="departureFlightNumber"
                            label-cols-md="4"
                            content-cols-lg="8"
                        >
                            <b-form-input
                                v-model="cwo.departureFlightNumber"
                                id="departureFlightNumber"
                            />
                        </b-form-group>
                        <b-form-group
                            class="row"
                            :label="$t('forms.arrivalDate')"
                            label-for="arrivalDate"
                            label-cols-md="4"
                            content-cols-lg="8"
                        >
                            <b-form-input
                                v-model="cwo.arrivalDate"
                                id="arrivalDate"
                            />
                            <div
                                class="input-errors"
                                v-for="error of vCwo$.cwo.arrivalDate.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>

                        <b-form-group
                            class="row"
                            :label="$t('forms.departureDate')"
                            label-for="departureDate"
                            label-cols-md="4"
                            content-cols-lg="8"
                        >
                            <b-form-input
                                id="departureDate"
                                v-model="cwo.departureDate"
                            />
                        </b-form-group>
                        <b-form-group
                            :class="{ row, error: vCwo$.stampSelect.$errors.length }"
                            :label="$t('forms.statement')"
                            label-for="stampSelect"
                            label-cols-md="4"
                            content-cols-lg="8"
                        >
                            <v-select
                                id="stampSelect"
                                v-model="stampSelect"
                                :options="stamps"
                            />
                            <div
                                class="input-errors"
                                v-for="error of vCwo$.stampSelect.$errors"
                                :key="error.$uid"
                            >
                                <small class="text-danger">{{ error.$message }}</small>
                            </div>
                        </b-form-group>
                    </div>
                    <b-form-group
                        :class="{ row, error: vCwo$.cwo.estimatedManHours.$errors.length }"
                        :label="$t('forms.estimatedManHours')"
                        label-for="estimatedManHours"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <datepicker
                            id="estimatedManHours"
                            v-model="cwo.estimatedManHours"
                            timePicker
                            :startTime="{ hours: 0, minutes: 0 }"
                            class="form-input"
                        />
                        <div
                            class="input-errors"
                            v-for="error of vCwo$.cwo.estimatedManHours.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row }"
                        :label="$t('forms.deadline')"
                        label-for="deadline"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            id="deadline"
                            v-model="cwo.deadline"
                        />
                    </b-form-group>
                    <b-form-group
                        :class="{ row }"
                        :label="$t('forms.criticalTask')"
                        label-for="criticalTask"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-checkbox v-model="cwo.criticalTask" id="criticalTask" switch/>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
        <div v-if="'id' in cwo" :class="{ disabled: actionsDisabled }">
            <audit-trail :statuses="this.cwo.statuses"/>
            <actions-form :cwoObject="this.cwo" @objectChange="setActions"/>
            <materials-form :cwoObject="this.cwo" @objectChange="setMaterials"/>
            <part-request-form :cwoObject="this.cwo" @objectChange="setPartRequests"/>
            <manpower-form :cwoObject="this.cwo" @objectChange="setManpowers"/>
        </div>
    </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import "@vuepic/vue-datepicker/dist/main.css";
import vSelect from "vue-select";
import Datepicker from "@vuepic/vue-datepicker";
import ActionsForm from "./ActionsForm.vue";
import MaterialsForm from "./MaterialsForm.vue";
import ManpowerForm from "./ManpowerForm.vue";
import AuditTrail from "@/views/Admin/Forms/Cwo/components/AuditTrail";
import PartRequestForm from "@/views/Admin/Forms/Cwo/components/PartRequestForm";
import {getUserData} from "@/auth/utils";

export default {
    components: {
        PartRequestForm,
        AuditTrail,
        vSelect,
        Datepicker,
        ActionsForm,
        MaterialsForm,
        ManpowerForm,
    },
    props: {
        cwoObject: {Object, required: true},
        action: {type: String, default: null},
        edit: {type: Boolean, default: false}
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        if (getUserData().role === "ROLE_CUSTOMER") {
            this.getUserList({params: {role: "ROLE_CUSTOMER", id: getUserData().id}}, this.customers);
        } else {
            this.getUserList({params: {role: "ROLE_CUSTOMER"}}, this.customers);
        }
        this.getResourceList(this.$Stations, this.stations);
        this.getResourceList(this.$AcTypes, this.acTypes);
        this.getServiceRequestList(this.$ServiceRequestTypes, this.services);
        this.getResourceList(this.$CsrStamps, this.stamps);
        this.cwo.providerInfo = this.$t("messages.providerInfo");
        this.currentDate = (new Date()).toUTCString();
        if ("id" in this.cwo) {
            this.stationSelect = {
                label: this.cwo.station.name,
                id: this.cwo.station["@id"],
            };
            this.customerSelect = {
                label: this.cwo.customer.customer,
                id: this.cwo.customer["@id"],
            };
            this.acTypeSelect = {
                label: this.cwo.acType.name,
                id: this.cwo.acType["@id"],
            };
            this.stampSelect = {
                label: this.cwo.csrStamp.name,
                id: this.cwo.csrStamp['@id'],
            };
        }
        if (this.cwo.estimatedManHours) {
            const time = this.cwo.estimatedManHours.split('.')
            this.cwo.estimatedManHours = {}
            this.cwo.estimatedManHours.hours = time[0]
            this.cwo.estimatedManHours.minutes = time[1]
            this.cwo.estimatedManHours.seconds = 0
        }
        if (this.cwo.arrivalDate) {
            this.cwo.arrivalDate = this.$helper.formatDateTimeToUTC(this.cwo.arrivalDate);
        }
        if (this.cwo.departureDate) {
            this.cwo.departureDate = this.$helper.formatDateTimeToUTC(this.cwo.departureDate);
        }
        if (this.cwo.deadline) {
            this.cwo.deadline = this.$helper.formatDateTimeToUTC(this.cwo.deadline);
        }
    },
    setup: () => ({vCwo$: useVuelidate({$scope: 'top'})}),
    validations() {
        return {
            stationSelect: {required},
            stampSelect: {required},
            acTypeSelect: {required},
            customerSelect: {required},
            cwo: {
                customerInfo: {required},
                serviceRequested: {required},
                estimatedManHours: {required},
                arrivalDate: {required},
            },
        };
    },
    computed: {
        isFormDisabled() {
            if ("id" in this.cwo) {
                return !this.allowEdit;
            } else {
                return false;
            }
        },
        isDeadlineDisabled() {
            if ("id" in this.cwo && this.cwo.status === 'DEFERRED') {
                return false;
            } else {
                return this.isFormDisabled;
            }
        },
        isEstimatedManHoursDisabled() {
            if ("id" in this.cwo && this.cwo.status === 'CONFIRMED') {
                return false;
            } else {
                return this.isFormDisabled;
            }
        },
        actionsDisabled() {
            if(getUserData().role === "ROLE_CUSTOMER") {
                return true
            }
            if ('id' in this.cwo) {
                return this.cwo.status === 'CLOSED' || this.cwo.status === 'VOIDED';
            }
            return true
        }
    },
    data() {
        return {
            cwo: this.cwoObject,
            customers: [],
            customerSelect: {},
            stationSelect: {},
            stations: [],
            acTypeSelect: {},
            acTypes: [],
            serviceSelect: {},
            services: [],
            stampSelect: {},
            stamps: [],
            currentDate: null,
            allowEdit: false,
            provider: this.$t("messages.providerInfo"),
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
        customerSelect() {
            this.loadCustomer();
        },
        serviceSelect() {
            this.cwo.serviceRequested = this.serviceSelect.description;
        },
        edit() {
            this.allowEdit = this.edit
        }
    },
    methods: {
        async onSubmit() {
            const isValid = await this.vCwo$.$validate();
            if (isValid) {
                if (this.acTypeSelect) {
                    this.cwo.acType = this.acTypeSelect.id;
                }
                if (this.stationSelect) {
                    this.cwo.station = this.stationSelect.id;
                }
                if (this.customerSelect) {
                    this.cwo.customer = this.customerSelect.id;
                }
                if (this.stampSelect) {
                    this.cwo.csrStamp = this.stampSelect.id;
                }
                if (this.cwo.arrivalDate) {
                    this.cwo.arrivalDate = this.$helper.formatDateTimeToSend(this.cwo.arrivalDate)

                }
                if (this.cwo.departureDate) {
                    this.cwo.departureDate = this.$helper.formatDateTimeToSend(this.cwo.departureDate)
                }
                if (this.cwo.deadline) {
                    this.cwo.deadline = this.$helper.formatDateTimeToSend(this.cwo.deadline)
                }
                if (this.cwo.estimatedManHours) {
                    this.cwo.estimatedManHours = this.cwo.estimatedManHours.hours + '.' + this.cwo.estimatedManHours.minutes
                }
                if (this.cwo.manpowers) {
                    this.cwo.manpowers.forEach(element => {
                        element.fromDate = this.$helper.formatDateTimeToSend(element.fromDate)
                        element.toDate = this.$helper.formatDateTimeToSend(element.toDate)
                    });
                }
                if (this.action === "add") {
                    this.create(
                        this.$Cwos,
                        this.cwo,
                        this.$t("messages.cwoCreated"),
                        null,
                        this.success,
                        this.error
                    );
                }
                if (
                    this.action === "CONFIRMED" ||
                    this.action === "CLOSED" ||
                    this.action === "VOIDED" ||
                    this.action === "NC" ||
                    this.action === "SAVE" ||
                    this.action === "DEFERRED"
                ) {
                    let cwo = this.cwo
                    this.cwo.status = this.action !== "SAVE" ? this.action : this.cwo.status;

                    if (this.action === "NC" || this.action === "VOIDED") {
                        cwo = {'id': this.cwo.id, 'status': this.cwo.status}
                    }
                    if (this.action === "DEFERRED" || this.action === "CLOSED") {
                        cwo = {
                            'id': this.cwo.id,
                            'actions': this.cwo.actions,
                            'manpowers': this.cwo.manpowers,
                            'materials': this.cwo.materials,
                            'partRequests': this.getPartRequests(),
                            'status': this.cwo.status
                        }
                    }
                    if (this.action === "SAVE") {
                        cwo.partRequests = this.getPartRequests()
                    }
                    this.update(
                        this.$Cwos,
                        this.cwo.id,
                        cwo,
                        this.$t("messages.cwoUpdated"),
                        null,
                        this.success,
                        this.error
                    );
                    this.$emit("clearAction");
                }
            } else {
                this.$emit("clearAction");
            }
        },
        setActions(data) {
            this.cwo.actions = data
        },
        getPartRequests() {
            let partRequest = [];
            this.cwo.partRequests.forEach(element => {
                if (typeof element.id === 'undefined') {
                    partRequest.push({
                        description: element.description,
                        partNumber: element.partNumber,
                        quantity: element.quantity,
                        remark: element.remark
                    });
                }
            });

            return partRequest;
        },
        setMaterials(data) {
            this.cwo.materials = data
        },
        setPartRequests(data) {
            this.cwo.partRequests = data
        },
        setManpowers(data) {
            this.cwo.manpowers = data
        },
        loadCustomer() {
            if ("numberId" in this.customerSelect) {
                this.$Users
                    .getResource({id: this.customerSelect.numberId})
                    .then((response) => {
                        this.cwo.customerInfo = response.data.customerData.orgInvoice;
                    });
            }
        },
        success(response) {
            if (this.cwo.id !== response.data.id) {
                window.location.href = '/cwo/' + response.data.id + '/edit'
            }
            this.$router.push(`/cwo`);
        },
        error() {
            this.$emit("clearAction");
        }
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

